var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-actions',{ref:"formCard",attrs:{"title":((_vm.editMode ? 'Ubah' : 'Tambah') + " " + (_vm.$route.meta.name.singular)),"no-actions":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Pendapatan Minimum","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Pendapatan Minimum","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.minimum_income),callback:function ($$v) {_vm.minimum_income=$$v},expression:"minimum_income"}},'money',_vm.money,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.submitErrors.name))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Jenis Sharing Fee","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Jenis Sharing Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"label","options":_vm.sharing_fee_type_option,"reduce":function (option) { return option.value; }},model:{value:(_vm.sharing_fee_type),callback:function ($$v) {_vm.sharing_fee_type=$$v},expression:"sharing_fee_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nilai Sharing Fee","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nilai Sharing Fee","rules":("required|integer" + (_vm.sharing_fee_type === 'percentage' ? '|max_value:100' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [(_vm.sharing_fee_type === 'rp')?_c('money',_vm._b({staticClass:"form-control",attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.sharing_fee_value),callback:function ($$v) {_vm.sharing_fee_value=$$v},expression:"sharing_fee_value"}},'money',_vm.money,false)):_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.sharing_fee_value),callback:function ($$v) {_vm.sharing_fee_value=$$v},expression:"sharing_fee_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nilai Maksimal Sharing Fee","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Nilai Maksimal Sharing Fee","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"state":errors.length > 0 || _vm.submitErrors.name ? false : null},model:{value:(_vm.max_nominal_sharing_fee),callback:function ($$v) {_vm.max_nominal_sharing_fee=$$v},expression:"max_nominal_sharing_fee"}},'money',_vm.money,false)),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('hr')],1),_c('b-col',{staticClass:"mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-50",attrs:{"variant":_vm.editMode ? 'warning' : 'primary',"type":"submit","disabled":_vm.loadingSubmit},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.loadingSubmit)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }